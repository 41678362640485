import md5 from "crypto-js/md5";

export async function AudioSynthesis(data) {
  console.log("合成参数", data);
  let params = {};
  if (data.code == 1) {
    // 阿里
    params = {
      appkey: data.appkey,
      token: data.token,
      text: data.text,
      format: "mp3",
      // voice 发音人，可选，默认是xiaoyun。
      voice: data.voice,
      // volume 音量，范围是0~100，可选，默认50。
      volume: data.volume,
      // speech_rate 语速，范围是-500~500，可选，默认是0。
      speech_rate: data.speech_rate,
      // pitch_rate 语调，范围是-500~500，可选，默认是0。
      pitch_rate: data.pitch_rate,
    };

    fetch("https://nls-gateway-cn-shanghai.aliyuncs.com/stream/v1/tts", {
      method: "POST",
      headers: {
        "X-NLS-Token": data.access_key_secret,
      },
      body: JSON.stringify(params),
    }).then((response) => {
      console.log("res", response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      response.blob().then((audioBlob) => {
        download(audioBlob);
      });
    });
  } else if (data.code == 2) {
    // 序列猴子
    const toBeHashed = `${data.access_key_id}+${data.access_key_secret}+${Math.floor(new Date().getTime() / 1000)}`;
    const encrypted = md5(toBeHashed).toString();
    params = {
      signature: encrypted,
      timestamp: Math.floor(new Date().getTime() / 1000),
      appkey: data.access_key_id,
      speaker: data.voice,
      text: data.text,
      speed: data.speed,
    };
    fetch("https://open.mobvoi.com/api/tts/v1", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then((response) => {
      console.log("res", response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      response.blob().then((audioBlob) => {
        download(audioBlob);
      });
    });
  } else {
    // 火山引擎
    params = {
      app: {
        appid: data.access_key_id,
        token: "access_token",
        cluster: "volcano_tts",
      },
      user: {
        uid: "uid123",
      },
      audio: {
        voice_type: data.voice,
        encoding: "mp3",
        compression_rate: 1,
        rate: 24000,
        speed_ratio: 1.0,
        volume_ratio: 1.0,
        pitch_ratio: 1.0,
        emotion: "happy",
        language: "cn",
      },
      request: {
        reqid: "uuid",
        text: data.text,
        text_type: "plain",
        operation: "query",
        silence_duration: "125",
        with_frontend: "1",
        frontend_type: "unitTson",
        pure_english_opt: "1",
      },
    };
    fetch("https://openspeech.bytedance.com/api/v1/tts", {
      method: "POST",
      headers: {
        Authorization: `Bearer;${data.token}`,
      },
      body: JSON.stringify(params),
    }).then((response) => {
      console.log("res", response);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      response.blob().then((audioBlob) => {
        download(audioBlob);
      });
    });
  }
}

export function download(audioBlob) {
  // 创建一个URL对象来指向Blob
  const audioUrl = URL.createObjectURL(audioBlob);

  // 创建一个下载链接并模拟点击以保存文件
  const link = document.createElement("a");
  link.href = audioUrl;
  link.download = "audio.mp3"; // 设置下载的文件名
  link.click();

  // 释放URL对象
  URL.revokeObjectURL(audioUrl);
}
