<template>
  <div class="bg conBox bdr8">
    <div class="df jc-sb bg-white head">
      <el-tabs v-model="activeName">
        <el-tab-pane label="商品列表" name="first"></el-tab-pane>
      </el-tabs>

      <el-button type="primary" icon="el-icon-circle-plus-outline" @click="dialogVisible = true">创建商品</el-button>
    </div>
    <div class="f-w box plr20">
      <div class="df f-w jc-sb" v-if="videoList.length > 0" v-infinite-scroll="load">
        <div v-for="item in videoList" :key="item.id" class="goodItem df" @click="showDialog(item)">
          <img :src="item.logo" object-fit="cover" alt="" />
          <div class="info">
            <div class="tit">{{ item.goods_name }}</div>
            <div class="mt5">{{ item.create_time }}</div>
            <!-- <div class="mt5">卡片id: <el-input v-model="item.card_id" type="number"></el-input></div> -->
          </div>
          <div class="tools" @click.stop="activeInfo = item">
            <el-dropdown trigger="click" placement="bottom" @command="optClick">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="edit" icon="el-icon-setting">编辑商品</el-dropdown-item>
                <el-dropdown-item command="del" icon="el-icon-delete">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div v-else>
        <myempty text="商品"></myempty>
      </div>
    </div>

    <el-dialog :title="title" :visible.sync="dialogVisible" :close-on-click-modal="false" :before-close="dialogClose" width="40%">
      <el-tabs v-model="dialogActive" @tab-click="handleClick">
        <el-tab-pane label="用户管理" name="first">
          <div class="Tip mb10 tal">给出的信息资料越详细，ai讲解过程效果越好</div>

          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" style="text-align: left">
            <el-form-item label="商品名称" prop="goods_name">
              <el-input v-model="ruleForm.goods_name"></el-input>
            </el-form-item>
            <el-form-item label="价格" prop="goods_price">
              <el-input v-model="ruleForm.goods_price"></el-input>
            </el-form-item>
            <el-form-item label="原价" prop="goods_original">
              <el-input v-model="ruleForm.goods_original"></el-input>
            </el-form-item>
            <el-form-item label="商品描述" prop="goods_text">
              <el-input type="textarea" v-model="ruleForm.goods_text"></el-input>
            </el-form-item>
            <el-form-item label="商品封面" prop="logo">
              <upload-file ref="upload" :logo="ruleForm.logo"></upload-file>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="详细信息" name="second">
          <div class="Tip mb10 tal">没有资料留空即可，信息精准度会影响直播间话术</div>

          <el-form :model="sForm" ref="sForm" label-position="top" style="text-align: left">
            <el-form-item label="商品亮点" prop="goods_merit">
              <el-input v-model="sForm.goods_merit"></el-input>
            </el-form-item>
            <el-form-item label="商品规格" prop="goods_norms">
              <el-input v-model="sForm.goods_norms"></el-input>
            </el-form-item>
            <el-form-item label="适用人群" prop="goods_crowd">
              <el-input v-model="sForm.goods_crowd"></el-input>
            </el-form-item>
            <el-form-item label="直播间优势" prop="goods_live_merit">
              <el-input v-model="sForm.goods_live_merit"></el-input>
            </el-form-item>
            <el-form-item label="直播保障" prop="goods_live_ensure">
              <el-input v-model="sForm.goods_live_ensure"></el-input>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="问答知识库" name="third">
          <div class="Tip mb10 tal">问答知识库，ai会根据提供的文字内容，全自动拟人回复</div>

          <div class="formBox" v-if="dialogActive == 'third'">
            <el-upload
              class="upload-demo"
              drag
              name="files"
              limit="5"
              :file-list="fileList"
              :action="action"
              :data="uploadData"
              :on-success="uploadSuccess"
              :on-error="uploadErr"
              :on-remove="uploadRemove"
              :on-exceed="uploadExceed"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip tal" slot="tip">只能上传txt文件，且不超过2MB</div>
            </el-upload>
          </div>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogClose">取 消</el-button>
        <el-button type="primary" v-show="dialogActive == 'first'" @click="dialogActive = 'second'">下一步</el-button>
        <el-button type="primary" v-show="dialogActive == 'second'" @click="saveGoods">保存</el-button>
        <el-button type="primary" v-show="dialogActive == 'third'" @click="dialogClose">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { get_goods_list, del_goods, add_goods, edit_goods } from "@/api/slive.js";
import { del_files, get_list_knowledge_base, get_list_files } from "@/api/miniature.js";

import myempty from "@/components/myempty.vue";
import UploadFile from "../../../components/UploadFile.vue";

export default {
  components: {
    myempty,
    UploadFile,
  },
  name: "second",
  props: {
    liveId: {
      type: Number,
      default: 0,
    },
    liveInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showlist: true,
      dialogVisible: false,
      activeName: "first",
      dialogActive: "first",
      ipt: "",
      activeInfo: {},
      uploadData: {},
      videoList: [],
      fileList: [],
      title: "创建商品",
      page: 1,
      pageNum: 1,
      tableData: [{ name: "111" }],
      ruleForm: {
        goods_name: "",
        goods_price: "",
        goods_original: "",
        goods_text: "",
        logo: "",
      },
      sForm: {
        goods_merit: "",
        goods_norms: "",
        goods_crowd: "",
        goods_live_merit: "",
        goods_live_ensure: "",
      },
      rules: {
        goods_name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
        logo: [
          {
            required: true,
            message: "请选择商品封面",
            trigger: ["blur", "change"],
          },
        ],
        goods_price: [
          { required: true, message: "请输入商品价格", trigger: "blur" },
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
      },
      action: process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/upload_file",
    };
  },
  mounted() {
    let fetUrl = localStorage.getItem("fetUrl");
    this.action = fetUrl+ "api/local_doc_qa/upload_file"
    this.getlist();
  },
  methods: {
    back() {
      this.showlist = true;
      this.getlist();
    },
    getlist(isFir = false) {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (isFir) {
        this.videoList = [];
        this.page = 1;
      }
      get_goods_list({
        page: this.page,
        item: 14,
        slive_id: this.liveId,
        so: "",
      }).then((res) => {
        loading.close();
        if (res.code == "200") {
          this.videoList.push(...res.data.list);
          this.pageNum = res.data.page_num;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    load() {
      if (this.page < this.pageNum) {
        this.page++;
        this.getlist();
      }
    },
    dialogClose() {
      this.dialogActive = "first";
      this.ruleForm = {};
      this.sForm = {};
      this.tableData = [];
      this.activeInfo = {};
      this.dialogVisible = false;
    },
    saveGoods() {
      let res = null;
      if (!this.$refs.upload.imageUrl) {
        this.$message.error("请上传商品封面");
        return;
      }
      let params = {
        slive_id: this.liveId,
        localhosts: this.$onlyId,
        ...this.ruleForm,
        ...this.sForm,
        logo: this.$refs.upload.imageUrl,
      };
      if (this.activeInfo.id) {
        res = edit_goods({
          goods_id: this.activeInfo.id,
          ...params,
        });
      } else {
        res = add_goods(params);
      }
      res.then((res) => {
        this.activeInfo = {};
        if (res.code == "200") {
          this.$message.success(res.msg);
          this.getlist(true);
          this.dialogVisible = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    handleDel() {},
    handleClick() {
      if (this.dialogActive == "third") {
        this.uploadData = {
          user_id: localStorage.getItem("token"),
          localhosts: this.$onlyId,
          kb_id: this.activeInfo.zb_id,
          mode: "strong",
        };
      }
    },
    showDialog(e) {
      this.activeInfo = e;
      this.getDetail();
    },
    getDetail() {
      this.title = "修改商品";
      this.dialogVisible = true;
      this.ruleForm = {
        goods_name: this.activeInfo.goods_name,
        goods_price: this.activeInfo.goods_price,
        goods_original: this.activeInfo.goods_original,
        goods_text: this.activeInfo.goods_text,
        logo: this.activeInfo.logo,
      };
      this.sForm = {
        goods_merit: this.activeInfo.goods_merit,
        goods_norms: this.activeInfo.goods_norms,
        goods_crowd: this.activeInfo.goods_crowd,
        goods_live_merit: this.activeInfo.goods_live_merit,
        goods_live_ensure: this.activeInfo.goods_live_ensure,
      };
      get_list_knowledge_base({
        localhosts: this.$onlyId,
        user_id: localStorage.getItem("token"),
      }).then((res) => {
        this.fileList = [];

        res.data.data.map((item) => {
          if (item.kb_id == this.activeInfo.zb_id) {
            get_list_files({
              localhosts: this.$onlyId,
              kb_id: item.kb_id,
              user_id: localStorage.getItem("token"),
            }).then((res1) => {
              console.log("res1", res1.data.data.details);
              res1.data.data.details.map((i) => {
                if (i.status == "green") {
                  this.fileList.push({
                    name: i.file_name,
                    file_id: i.file_id,
                  });
                }
              });
            });
          }
        });
      });
    },
    optClick(command) {
      console.log(this.activeInfo);
      this.type = command;
      switch (command) {
        case "edit":
          this.getDetail();
          break;
        case "del":
          this.$confirm("此操作将永久删除该视频, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then((_) => {
              del_goods({
                goods_id: this.activeInfo.id,
              }).then((res) => {
                if (res.code == "200") {
                  this.$message.success(res.msg);
                  this.getlist(true);
                } else {
                  this.$message.error(res.msg);
                }
              });
            })
            .catch((_) => {});
          break;
        default:
          break;
      }
    },
    toLive() {
      this.$router.push({
        name: "realTimeCreate",
        params: { id: this.activeInfo.id, title: this.ipt },
      });
    },
    createLive() {
      if (this.ipt == "") {
        this.$message.error("请输入方案名称");
        return;
      }
      this.dialogVisible = false;
      this.$router.push({
        name: "realTimeCreate",
        params: { id: this.activeInfo.id, title: this.ipt },
      });
    },
    uploadSuccess(e) {
      if (e.code == 200) {
        this.$message.success(e.msg);
      }
    },
    uploadErr(e) {
      this.$message.success(e.msg);
    },
    uploadExceed(e) {
      this.$message.success("已超过最大上传文件个数，请删除后再上传");
    },
    uploadRemove(e) {
      console.log("uploadRemove", e);
      let params = {
        user_id: localStorage.getItem("token"),
        localhosts: this.$onlyId,
        kb_id: this.activeInfo.zb_id,
        file_ids: e.file_id,
      };
      del_files(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("删除成功");
        } else {
          this.$message.error("删除失败");
        }
      });
    },
    closeDialog() {
      this.activeInfo = {};
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep {
  .bg-white{
    background: rgba(44, 50, 64, 1);
  }
  .el-dialog__header {
    padding: 20px 20px 10px;
    text-align: left;
    font-weight: bold;
  }
  .el-textarea__inner {
    padding-bottom: 40px;
  }
  .el-textarea .el-input__count {
    background: #f3f5fd !important;
  }
  .el-form-item__label {
    padding: 0 !important;
  }
  .el-dialog {
    border-radius: 10px;
  }
  .el-form-item {
    margin-bottom: 12px;
  }
  .el-form-item:last-child {
    margin-bottom: 0;
  }
  .el-dialog__body {
    padding: 0px 30px;
  }
}
/deep/.el-icon-circle-plus-outline{
  font-size: 16px;
}
/deep/.el-tabs__item.is-active{
  color: rgba(16, 226, 177, 1);
}
.el-tabs__item:hover{
  color: rgba(16, 226, 177, 1);
}
/deep/.el-dialog__header{
  background: rgba(44, 50, 64, 1);
  border-radius: 8px 8px 0 0;
}
/deep/.el-dialog__title{
  color: rgba(19, 243, 193, 1);
}
/deep/.el-dialog__footer{
  background: #2c3240;
  border-radius: 0 0 0.5rem 0.5rem;
}
/deep/.el-dialog__body{
  background: rgba(31, 37, 49, 1);
  color: white;
}
::v-deep{
  .el-tabs__item{
    color: white;
  }
  .el-form-item__label{
    color: white;
  }
  .mb10{
    color: #13f3c1;
  }
  input[type='text'], input[type='password'], input[type='number'], textarea{
    background: rgba(15, 18, 25, 1);
    color: white;
    width: 80%;
    margin-left: 15%;
    border-radius: 8px;
    border: none;
    /*border: 0px solid white;*/
  }
  .el-form-item__error{
    margin-left: 50%;
  }
  .el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus, .el-message-box__input input.invalid, .el-message-box__input input.invalid:focus{
    border: 1px solid red;
  }
  .el-input.is-active .el-input__inner, .el-input__inner:focus{
    border: 1px solid #13f3c1;
  }
  [data-v-140f2d94] .el-form-item:last-child{
    margin-bottom: 30px;
  }
  .el-upload__tip{
    color: red;
    margin-bottom: 30px;
    margin-top: 10px;
  }
  [data-v-6988d2cd] .el-upload-dragger{
    margin-top: 15px;
    margin-left: 38%;
    background: #0f1219;
    width: 32rem;
  }
  [data-v-6988d2cd] .el-upload__text{
    color: white;
  }
  .el-upload-dragger .el-upload__text em{
    color: #13f3c1;
    margin-left: 1%;
  }
  .el-upload-dragger{
    background: #0f1219;
  }
  .el-upload-dragger .el-upload__text{
    color: white;
  }
  .el-upload-dragger .el-icon-upload{
    color: white;
  }
  .el-upload-list__item-name{
    color: white;
  }
  .el-tree-node__content:hover, .el-upload-list__item:hover{
    background: #00c8c8;
  }
  .el-tabs__nav-wrap::after{
    border: none;
    background-color: transparent;
  }
  .el-tabs__active-bar{
    border-color: transparent;
    background: transparent;
  }
}
.conBox {
  overflow: hidden;
  height: 100%;
  width: 87vw;
  background: rgba(31, 37, 49, 1);
  border-radius: 10px;
  margin-right: 25px;
  margin-top: 30px;
}
.box {
  width: 99.5%;
  height: 90%;
  padding-bottom: 15px;
  padding-top: 20px;
}
/deep/.emp_color{
  background: #1f2531;
}
/deep/.el-button--primary{
  border: none;
  border-radius: 10px;
  color: #000c17;
  background: rgba(16, 226, 177, 1) !important;
  font-size: 16px;
}
.goodItem {
  background: rgba(1, 1, 18, 1);
  box-shadow: 0px 5px 15px 0px rgba(104, 104, 104, 0.35);

  width: 49%;
  height: 160px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;

  img {
    width: 120px;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
  }
  .info {
    margin-left: 20px;
    text-align: left;
    width: 86%;
    color: white;
    padding: 10px;

    .tit {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .btn {
      background: #d9edf5;
      padding: 1px 7px;
      color: #4284cf;
      border: 1px solid #4284cf;
      border-radius: 4px;
      margin-right: 6px;
    }
    /deep/.el-input {
      width: 70px;
    }
  }

  .tools {
    position: relative;
    z-index: 99;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: transparent;
    color: white;
    border-radius: 5px;
    border: 1px solid rgba(216, 216, 216, 1);
    top: 0px;
    left: 5px;
  }
}
/deep/.el-dropdown{
  color: white;
}
.uploadDemo {
  padding: 0 35px;
  .upList {
    .title {
      font-size: 16px;
      font-weight: 600;
      text-align: left;
    }
  }
}
</style>
